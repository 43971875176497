import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import Router from "./routes/Router";

//React Redux
import { useDispatch } from "react-redux";
import {
  setAccountAddress,
  setAccountEnsName,
  setAccountProvider,
  setAccountSigner,
  setAccountBalance,
  setUsdcBalance,
  setUsdcAllowance,
  disconnectAccount,
  setNftBalance,
} from "./redux/reducers/account";
import {
  setContractAddress,
  setContractOwner,
  setContractBalance,
  setContractUsdcBalance,
  setContractTotalSupply,
  setContractPhase,
  setContractTokenUri,
  setContractPriceUSDC,
  setContractPriceETH,
  setContractMaxSupply,
} from "./redux/reducers/contract";
import {
  setOracleAddress,
  setOraclePrice,
  setOracleDecimals,
} from "./redux/reducers/oracle";

//Wagmi
import {
  useAccount,
  usePublicClient,
  useContractRead,
  useWalletClient,
} from "wagmi";
import { fetchBalance, fetchEnsName } from "@wagmi/core";
//Wagmi Types
import type { UseContractReadConfig } from "wagmi";

//Constants
import {
  USDC,
  CONTRACT_ADDRESS,
  CHAINLINK_ORACLE,
  CHAINLINK_ORACLE_DECIMALS,
  USDC_DECIMALS,
} from "./lib/constants";
//Abi
import ZodiakAbi from "./lib/abi/Zodiak.json";
import OracleAbi from "./lib/abi/EACAggregatorProxy.json";
import UsdcAbi from "./lib/abi/FiatTokenProxy.json";
import { ethers } from "ethers";

const contractConfig = {
  address: CONTRACT_ADDRESS,
  abi: ZodiakAbi,
};

const oracleConfig = {
  address: CHAINLINK_ORACLE,
  abi: OracleAbi,
};

const usdcConfig = {
  address: USDC,
  abi: UsdcAbi,
};

function App() {
  const dispatch = useDispatch();
  const routing = useRoutes(Router);
  const [isMounted, setIsMounted] = useState(false);

  /* ------------------------------- Oracle Data ------------------------------ */
  const { data: latestAnswer }: any = useContractRead({
    ...oracleConfig,
    functionName: "latestAnswer",
    watch: true,
  } as UseContractReadConfig);
  const { data: decimals }: any = useContractRead({
    ...oracleConfig,
    functionName: "decimals",
    watch: true,
  } as UseContractReadConfig);

  //Set Redux State
  dispatch(setOracleAddress(CHAINLINK_ORACLE));
  useEffect(() => {
    decimals && dispatch(setOracleDecimals(Number(decimals)));
    latestAnswer &&
      dispatch(
        setOraclePrice(
          Number(
            ethers.utils.formatUnits(
              latestAnswer,
              decimals || CHAINLINK_ORACLE_DECIMALS
            )
          ).toFixed(2)
        )
      );
  }, [dispatch, latestAnswer, decimals]);

  /* ------------------------------ Contract Data ----------------------------- */
  const { data: owner }: any = useContractRead({
    ...contractConfig,
    functionName: "owner",
    watch: true,
  } as UseContractReadConfig);
  const { data: totalSupply }: any = useContractRead({
    ...contractConfig,
    functionName: "totalSupply",
    watch: true,
  } as UseContractReadConfig);
  const { data: maxSupply }: any = useContractRead({
    ...contractConfig,
    functionName: "MAX_SUPPLY",
    watch: true,
  } as UseContractReadConfig);
  const { data: phase }: any = useContractRead({
    ...contractConfig,
    functionName: "getPhase",
    watch: true,
  } as UseContractReadConfig);
  const { data: tokenUri }: any = useContractRead({
    ...contractConfig,
    functionName: "imgUri",
    watch: true,
  } as UseContractReadConfig);
  const { data: priceUSDC }: any = useContractRead({
    ...contractConfig,
    functionName: "PRICE",
    watch: true,
  } as UseContractReadConfig);
  const { data: priceETH }: any = useContractRead({
    ...contractConfig,
    functionName: "PRICE_ETH",
    watch: true,
  } as UseContractReadConfig);

  //Set Redux State
  CONTRACT_ADDRESS && dispatch(setContractAddress(CONTRACT_ADDRESS));
  useEffect(() => {
    owner && dispatch(setContractOwner(owner));
    totalSupply && dispatch(setContractTotalSupply(Number(totalSupply)));
    maxSupply && dispatch(setContractMaxSupply(Number(maxSupply)));
    phase && dispatch(setContractPhase(Number(phase.toString())));
    tokenUri && dispatch(setContractTokenUri(tokenUri));
    priceUSDC &&
      dispatch(
        setContractPriceUSDC(
          Number(ethers.utils.formatUnits(priceUSDC, USDC_DECIMALS))
        )
      );
    priceETH &&
      dispatch(setContractPriceETH(Number(ethers.utils.formatUnits(priceETH))));
  }, [
    dispatch,
    owner,
    totalSupply,
    maxSupply,
    phase,
    tokenUri,
    priceUSDC,
    priceETH,
  ]);

  CONTRACT_ADDRESS &&
    fetchBalance({
      address: CONTRACT_ADDRESS,
    })
      .then((balance) => dispatch(setContractBalance(balance)))
      .catch((e) => console.error("Error fetching contract balance", e));
  CONTRACT_ADDRESS &&
    fetchBalance({
      address: CONTRACT_ADDRESS,
      token: USDC,
    })
      .then((balance) => dispatch(setContractUsdcBalance(balance)))
      .catch((e) => console.error("Error fetching contract balance", e));

  /* ------------------------------ Account Data ------------------------------ */

  const { address } = useAccount();
  //Account Balance ETH
  address &&
    fetchBalance({
      address: address,
    })
      .then((balance) => dispatch(setAccountBalance(balance)))
      .catch((e) => console.error("Error fetching eth balance", e));
  //Account Balance USDC
  address &&
    fetchBalance({
      address: address,
      token: USDC,
    })
      .then((balance) => dispatch(setUsdcBalance(balance)))
      .catch((e) => console.error("Error fetching usdc balance", e));
  //Account NFT Balance
  const { data: balanceOf }: any = useContractRead({
    ...contractConfig,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  } as UseContractReadConfig);
  //Account NFT Balance
  const { data: usdcAllowance }: any = useContractRead({
    ...usdcConfig,
    functionName: "allowance",
    args: [address, CONTRACT_ADDRESS],
    watch: true,
  } as UseContractReadConfig);
  //Account Ens Name
  address
    ? fetchEnsName({
        address: address,
      }).then((ensName) => dispatch(setAccountEnsName(ensName)))
    : dispatch(setAccountEnsName(null));
  //Signer
  const { data: signer } = useWalletClient();

  //Set Redux State
  dispatch(setAccountProvider(usePublicClient().chains?.[0]));
  useEffect(() => {
    address && dispatch(setAccountAddress(address));
    usdcAllowance && dispatch(setUsdcAllowance(Number(usdcAllowance)));
    balanceOf &&
      dispatch(setNftBalance(Number(ethers.utils.formatUnits(balanceOf, 0))));
    signer && dispatch(setAccountSigner(signer));
  }, [dispatch, address, usdcAllowance, balanceOf, signer]);

  useAccount({
    onDisconnect: () => {
      dispatch(disconnectAccount());
    },
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return <>{routing}</>;
}

export default App;
