//Importing Interfaces
import { FetchBalanceResult } from "./types/reduxInterface";

export const CONTRACT_ADDRESS = undefined; //Mainnet

export const USDC = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"; //Mainnet
export const USDC_DECIMALS = 6;

export const USDC_PRICE = 3333;

//CHAINLINK
export const CHAINLINK_ORACLE = "0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419"; //Mainnet
export const CHAINLINK_ORACLE_DECIMALS = 8;

//ETHERSCAN
export const ETHERSCAN_URL = "https://etherscan.io/"; //Mainnet

//MAILCHIMP
export const id = "df5e5da048";
export const u = "006dc54934a82eeca2f10ce28";
export const postUrl = `https://gmail.us10.list-manage.com/subscribe/post?u=${u}&id=${id}`;

//IPFS
export const IPFS_BASE_URL = "https://ipfs.io/ipfs/";
export const IPFS_IMG_URL =
  IPFS_BASE_URL + "QmULCHVNWDv29fcTD2fw5BVLeKe3Sjat1iJTEFyd5tQRmo";


//WalletConnect
export const WC = "7b52c268439f0579d9d96909c9037823";

//Fetch Balance
export const ETH_BALANCE: FetchBalanceResult = {
  decimals: 18,
  formatted: "0",
  symbol: "ETH",
  value: BigInt(0),
};

export const USDC_BALANCE: FetchBalanceResult = {
  decimals: USDC_DECIMALS,
  formatted: "0",
  symbol: "USDC",
  value: BigInt(0),
};