import { createSlice } from "@reduxjs/toolkit";
import type { OracleInitialState as is } from "../../lib/types/reduxInterface";
import { CHAINLINK_ORACLE, CHAINLINK_ORACLE_DECIMALS } from "../../lib/constants";

const initialState: is = {
  address: CHAINLINK_ORACLE,
  decimals: CHAINLINK_ORACLE_DECIMALS,
  price: 1,
};

const oracleSlice = createSlice({
  name: "oracleInfo",
  initialState,
  reducers: {
    setOracleAddress: (state, action) => {
      state.address = action.payload;
    },
    setOracleDecimals: (state, action) => {
      state.decimals = action.payload;
    },
    setOraclePrice: (state, action) => {
      state.price = action.payload;
    },
  },
});

//Select the oracle
export const selectOracle = (state: any) => state.oracle;
//Select the oracle Data
export const selectOracleAddress = (state: any) => state.oracle.address;
export const selectOracleDecimals = (state: any) => state.oracle.decimals;
export const selectOraclePrice = (state: any) => state.oracle.price;

export const { setOracleAddress, setOracleDecimals, setOraclePrice } =
  oracleSlice.actions;

export default oracleSlice.reducer;
