import { createSlice } from "@reduxjs/toolkit";
import type { AccountInitialState as is } from "../../lib/types/reduxInterface";
import { ETH_BALANCE, USDC_BALANCE } from "../../lib/constants";

const initialState: is = {
  address: "0x0000000000000000000000000000000000000000",
  ensName: undefined,
  provider: undefined,
  signer: undefined,
  balance: ETH_BALANCE,
  usdcBalance: USDC_BALANCE,
  usdcAllowance: 0,
  nftBalance: 0,
};

const accountSlice = createSlice({
  name: "accountInfo",
  initialState,
  reducers: {
    setAccountAddress(state, action) {
      state.address = action.payload;
    },
    setAccountEnsName(state, action) {
      state.ensName = action.payload;
    },
    setAccountProvider(state, action) {
      state.provider = action.payload;
    },
    setAccountSigner(state, action) {
      state.signer = action.payload;
    },
    setAccountBalance(state, action) {
      state.balance = action.payload;
    },
    setUsdcBalance(state, action) {
      state.usdcBalance = action.payload;
    },
    setUsdcAllowance(state, action) {
      state.usdcAllowance = action.payload;
    },
    setNftBalance(state, action) {
      state.nftBalance = action.payload;
    },
    disconnectAccount() {
      return initialState;
    },
  },
});

//Select the account
export const selectAccount = (state: any) => state.account;
//Select the account Data
export const selectAccountAddress = (state: any) => state.account.address;
export const selectAccountEnsName = (state: any) => state.account.ensName;
//Select the balance Data
export const selectAccountBalance = (state: any) => state.account.balance;
export const selectAccountBalanceDecimals = (state: any) =>
  state.account.balance?.decimals;
export const selectAccountBalanceAmount = (state: any) =>
  state.account.balance?.formatted;
export const selectAccountBalanceSymbol = (state: any) =>
  state.account.balance?.symbol;
//Select the USDC balance Data
export const selectUsdcBalance = (state: any) => state.account.usdcBalance;
export const selectUsdcBalanceDecimals = (state: any) =>
  state.account.usdcBalance?.decimals;
export const selectUsdcBalanceAmount = (state: any) =>
  state.account.usdcBalance?.formatted;
export const selectUsdcBalanceSymbol = (state: any) =>
  state.account.usdcBalance?.symbol;
//Select the USDC allowance Data
export const selectUsdcAllowance = (state: any) => state.account.usdcAllowance;
//Select the NFT balance Data
export const selectNftBalance = (state: any) => state.account.nftBalance;
export const selectNftBalanceDecimals = (state: any) =>
  state.account.nftBalance?.decimals;
export const selectNftBalanceAmount = (state: any) =>
  state.account.nftBalance?.formatted;
export const selectNftBalanceSymbol = (state: any) =>
  state.account.nftBalance?.symbol;
//Select the provider Data
export const selectChainId = (state: any) => state.account.provider?.chainId;
export const selectProviderName = (state: any) => state.account.provider?.name;
export const selectProviderNativeCurrency = (state: any) =>
  state.account.provider?.nativeCurrency;
//Select RPC URL
export const selectRpcUrl = (state: any) =>
  state.account.provider?.rpcUrls.default.http[0];
export const selectRpcUrlAlchemy = (state: any) =>
  state.account.provider?.rpcUrls.alchemy.http[0];
export const selectRpcUrlInfura = (state: any) =>
  state.account.provider?.rpcUrls.infura.http[0];
export const selectRpcUrlPublic = (state: any) =>
  state.account.provider?.rpcUrls.public.http[0];
//Select the Signer
export const selectSigner = (state: any) => state.account.signer;

export const {
  setAccountAddress,
  setAccountEnsName,
  setAccountProvider,
  setAccountSigner,
  setAccountBalance,
  setUsdcBalance,
  setUsdcAllowance,
  setNftBalance,
  disconnectAccount,
} = accountSlice.actions;

export default accountSlice.reducer;
