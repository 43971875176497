import { createSlice } from "@reduxjs/toolkit";
import type { ContractInitialState as is } from "../../lib/types/reduxInterface";
import {
  CONTRACT_ADDRESS,
  ETH_BALANCE,
  IPFS_IMG_URL,
  USDC_BALANCE,
  USDC_PRICE,
} from "../../lib/constants";

const initialState: is = {
  address: CONTRACT_ADDRESS,
  owner: "0x0000000000000000000000000000000000000000",
  balance: ETH_BALANCE,
  usdcBalance: USDC_BALANCE,
  totalSupply: 0,
  maxSupply: 333,
  phase: 0,
  tokenUri: IPFS_IMG_URL,
  priceUSDC: USDC_PRICE,
  priceETH: 0,
};

const contractSlice = createSlice({
  name: "contractInfo",
  initialState,
  reducers: {
    setContractAddress: (state, action) => {
      state.address = action.payload;
    },
    setContractOwner: (state, action) => {
      state.owner = action.payload;
    },
    setContractBalance: (state, action) => {
      state.balance = action.payload;
    },
    setContractUsdcBalance: (state, action) => {
      state.usdcBalance = action.payload;
    },
    setContractTotalSupply: (state, action) => {
      state.totalSupply = action.payload;
    },
    setContractMaxSupply: (state, action) => {
      state.maxSupply = action.payload;
    },
    setContractPhase: (state, action) => {
      state.phase = action.payload;
    },
    setContractTokenUri: (state, action) => {
      state.tokenUri = action.payload;
    },
    setContractPriceUSDC: (state, action) => {
      state.priceUSDC = action.payload;
    },
    setContractPriceETH: (state, action) => {
      state.priceETH = action.payload;
    },
  },
});

//Select the contract
export const selectContract = (state: any) => state.contract;
//Select the contract Data
export const selectContractAddress = (state: any) => state.contract.address;
export const selectContractOwner = (state: any) => state.contract.owner;
//Select the balance Data
export const selectContractBalance = (state: any) => state.contract.balance;
export const selectContractBalanceDecimals = (state: any) =>
  state.contract.balance?.decimals;
export const selectContractBalanceAmount = (state: any) =>
  state.contract.balance?.formatted;
export const selectContractBalanceSymbol = (state: any) =>
  state.contract.balance?.symbol;
//Select the USDC balance Data
export const selectContractUsdcBalance = (state: any) =>
  state.contract.usdcBalance;
export const selectContractUsdcBalanceDecimals = (state: any) =>
  state.contract.usdcBalance?.decimals;
export const selectContractUsdcBalanceAmount = (state: any) =>
  state.contract.usdcBalance?.formatted;
export const selectContractUsdcBalanceSymbol = (state: any) =>
  state.contract.usdcBalance?.symbol;
//Select the total supply Data
export const selectContractTotalSupply = (state: any) =>
  state.contract.totalSupply;
export const selectContractTotalSupplyDecimals = (state: any) =>
  state.contract.totalSupply?.decimals;
export const selectContractTotalSupplyAmount = (state: any) =>
  state.contract.totalSupply?.formatted;
export const selectContractTotalSupplySymbol = (state: any) =>
  state.contract.totalSupply?.symbol;
//Select the max supply Data
export const selectContractMaxSupply = (state: any) => state.contract.maxSupply;
export const selectContractMaxSupplyAmount = (state: any) =>
  state.contract.maxSupply?.formatted;
//Select the phase Data
export const selectContractPhase = (state: any) => state.contract.phase;
//Select the tokenUri Data
export const selectContractTokenUri = (state: any) => state.contract.tokenUri;
//Select the priceUSDC Data
export const selectContractPriceUSDC = (state: any) => state.contract.priceUSDC;
export const selectContractPriceUSDCDecimals = (state: any) =>
  state.contract.priceUSDC?.decimals;
export const selectContractPriceUSDCAmount = (state: any) =>
  state.contract.priceUSDC?.formatted;
export const selectContractPriceUSDCSymbol = (state: any) =>
  state.contract.priceUSDC?.symbol;
//Select the priceETH Data
export const selectContractPriceETH = (state: any) => state.contract.priceETH;
export const selectContractPriceETHDecimals = (state: any) =>
  state.contract.priceETH?.decimals;
export const selectContractPriceETHAmount = (state: any) =>
  state.contract.priceETH?.formatted;
export const selectContractPriceETHSymbol = (state: any) =>
  state.contract.priceETH?.symbol;

export const {
  setContractAddress,
  setContractOwner,
  setContractBalance,
  setContractUsdcBalance,
  setContractTotalSupply,
  setContractMaxSupply,
  setContractPhase,
  setContractTokenUri,
  setContractPriceUSDC,
  setContractPriceETH,
} = contractSlice.actions;

export default contractSlice.reducer;
