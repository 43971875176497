import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
//React Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";

//Raimbow Kit
import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
  getDefaultWallets,
  RainbowKitProvider,
  Theme,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  ledgerWallet,
  trustWallet,
  okxWallet,
  argentWallet,
  injectedWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
//Wagmi
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
//Merge
import merge from "lodash.merge";
//CSS
import "./assets/style/index.scss";
//Constants
import { WC } from "./lib/constants";

const { chains, publicClient, webSocketPublicClient } = configureChains([mainnet], [publicProvider()]);

const projectId = WC;
const { wallets } = getDefaultWallets({
  appName: "Zodiak",
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "Other",
    wallets: [
      ledgerWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
      argentWallet({ projectId, chains }),
      injectedWallet({ chains }),
      coinbaseWallet({ chains, appName: "Zodiak" }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = merge(darkTheme(), {
  colors: {
    accentColor: "#6461f4",
    modalBackground: "#232323",
  },
  fonts: {
    body: "Lexend, sans-serif",
  },
} as Theme);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        //modalSize="compact"
        coolMode={true}
        chains={chains}
        initialChain={mainnet}
        theme={theme}
      >
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
