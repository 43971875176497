import { configureStore, combineReducers } from "@reduxjs/toolkit";
import account from "./reducers/account";
import contract from "./reducers/contract";
import oracle from "./reducers/oracle";

const combinedReducers = combineReducers({
  account,
  contract,
  oracle,
});
export const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
