import { lazy } from "react";
import Loadable from "../layout/loadable";

/* ***Layout**** */
const Layout = Loadable(lazy(() => import("../layout")));

/* ***Pages**** */
const Home = Loadable(lazy(() => import("../pages/home")));
const Nft = Loadable(lazy(() => import("../pages/nft/nft")));
const Validate = Loadable(lazy(() => import("../pages/nft")));

/* ***Error**** */
const Error404 = Loadable(lazy(() => import("../pages/404")));

const Router = [
  {
    path: "/",
    element: <Layout />,
    children: [{ path: "/", element: <Home /> }],
  },
  // Pahse 1 Url
  {
    path: "/presale",
    element: <Layout />,
    children: [{ path: "/presale", element: <Nft /> }],
  },
  // Pahse 2 Url
  {
    path: "/nft/:address",
    element: <Layout />,
    children: [{ path: "/nft/:address", element: <Validate /> }],
  },
  // Pahse 3 Url
  {
    path: "/nft",
    element: <Layout />,
    children: [{ path: "/nft", element: <Nft /> }],
  },

  // 404
  {
    path: "*",
    element: <Layout />,
    children: [{ path: "*", element: <Error404 /> }],
  },
];

export default Router;
